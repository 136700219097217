@use "sass:color";

$header-padding: 64px;
$body-color: #676767;
$background-color: #f5f5dc;
$gold-color: #997f35;
$beige-color: #f5f5dc;
$green-color: #284c4a;
$forest-green: #228b22;
$sm-breakpoint: 700px;

/* Reset */
/* Box sizing rules */
*,
*::after {
  box-sizing: border-box;
}

/* Prevent font size inflation */
html {
  -moz-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
}

/* Remove default margin in favour of better control in authored CSS */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin-block-end: 0;
}

figure {
  margin: 1em auto;
}

figcaption {
  font-size: 0.75em;
  color: color.adjust($body-color, $lightness: 10%);
  padding: 0.5em 1em;
  text-align: center;
}

@media screen and (max-width: 1200px) {
  figcaption {
    text-align: left;
  }
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role="list"],
ol[role="list"] {
  list-style: none;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  line-height: 1.5;
}

/* Set shorter line heights on headings and interactive elements */
h1,
h2,
h3,
h4,
button,
input,
label {
  line-height: 1.1;
}

/* Balance text wrapping on headings */
h1,
h2,
h3,
h4 {
  text-wrap: balance;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
  color: currentColor;
  text-decoration: none;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  height: auto;
  display: block;
  margin: auto;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Make sure textareas without a rows attribute are not tiny */
textarea:not([rows]) {
  min-height: 10em;
}

/* Anything that has been anchored to should have extra scroll margin */
:target {
  scroll-margin-block: 5ex;
}

.italic {
  font-style: italic;
}

.text-center {
  text-align: center !important;
}

@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");

@font-face {
  font-family: "Parkford";
  font-style: normal;
  font-weight: normal;
  src: local("Parkford"), url("webFonts/Parkford/Parkford.woff2") format("woff");
}

@font-face {
  font-family: "PaskExpanded";
  src: url("webFonts/PaskExpanded/font.woff2") format("woff2"),
    url("webFonts/PaskExpanded/font.woff") format("woff");
}
@font-face {
  font-family: "PaskOblique";
  src: url("webFonts/PaskOblique/font.woff2") format("woff2"),
    url("webFonts/PaskOblique/font.woff") format("woff");
}
@font-face {
  font-family: "PaskRegular";
  src: url("webFonts/PaskRegular/font.woff2") format("woff2"),
    url("webFonts/PaskRegular/font.woff") format("woff");
}
@font-face {
  font-family: "PaskExpandedOblique";
  src: url("webFonts/PaskExpandedOblique/font.woff2") format("woff2"),
    url("webFonts/PaskExpandedOblique/font.woff") format("woff");
}
@font-face {
  font-family: "PaskCondensedOblique";
  src: url("webFonts/PaskCondensedOblique/font.woff2") format("woff2"),
    url("webFonts/PaskCondensedOblique/font.woff") format("woff");
}
@font-face {
  font-family: "PaskCondensed";
  src: url("webFonts/PaskCondensed/font.woff2") format("woff2"),
    url("webFonts/PaskCondensed/font.woff") format("woff");
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: black;
  color: $body-color;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

code {
  font-family: "Monaco", "Consolas", "Courier New", monospace;
  text-transform: none !important;
}

.inline-code {
  display: inline;
  padding: 0.375em;
  border-radius: 0.25em;
  background-color: lighten($beige-color, 5%);
}

canvas {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

p {
  font-size: 1em;
  text-align: left;
}

/* Bootstrap Grid & Form */
.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

button,
input {
  overflow: visible;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.btn-primary {
  background: #222;
  color: #fff;
  border-color: #222;
}
.btn {
  font-size: 14px;
  text-transform: capitalize;
  padding: 10px 25px;
  border-radius: 0;
  border: 1px solid;
  position: relative;
  z-index: 1;
  transition: 0.2s ease;
}

.form-group {
  margin-bottom: 20px;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control {
  width: 100%;
  height: 50px;
  border: 1px solid #eee;
  background-color: transparent;
  color: #333;
}

.form-group .form-control {
  border: 1px solid #cdcdcd;
  border-radius: 0;
  padding: 0 20px;
  height: 50px;
  background-color: inherit;
  color: #333;
}

textarea.form-control {
  height: 150px !important;
  padding: 20px !important;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

@media (min-width: 768px) {
  .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.container {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border: calc($header-padding / 2) solid $green-color;
  box-shadow: 0 0 0 1px #997f34 inset,
    0 0px 0 $header-padding $green-color inset,
    0 0 0 calc($header-padding + 1px) #997f34 inset;
}

.container-enter {
  opacity: 0;
}

.container-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}

.container-exit {
  opacity: 1;
  border-width: calc($header-padding / 4);
  border-color: beige;
  box-shadow: 0 0 0 0 beige;
  transition: all 300ms;
}

.container-exit-active {
  opacity: 0;
  transition: opacity 100ms;
}

.container.not-index {
  transition: all 300ms;
  border-width: calc($header-padding / 4);
  border-color: beige;
  background-color: #f5f5dc;
  box-shadow: 0 0 0 0 beige;
}

.not-index .title-wrapper {
  position: relative;
  top: 0;
}

.mx-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: $header-padding;
  position: relative;
  z-index: 1000;
}

@media screen and (min-width: 1000px) {
  header {
    padding-bottom: 0;
  }
}

.content-wrapper {
  scrollbar-color: #fffff0 beige;
  width: 90%;
  margin: 0 auto $header-padding auto;
  max-width: 900px;
  overflow-y: auto;
}

@media screen and (min-width: 1200px) {
  .content-wrapper {
    max-width: 100%;
    max-width: 1200px;
  }
}

.content-wrapper:after {
  width: 100%;
  content: "";
  position: absolute;
  bottom: $header-padding;
  height: calc($header-padding / 2);
  background-image: linear-gradient(to bottom, transparent, $background-color);
}

.contact-wrapper {
  scrollbar-color: #fffff0 beige;
  width: 90%;
  margin: 2em auto 0 auto;
  max-width: 900px;
  overflow-y: auto;
}

@media screen and (min-width: 1200px) {
  .contact-wrapper {
    max-width: 100%;
    margin-top: 3em;
  }

  .contact-wrapper > p {
    margin: 1em auto;
  }
}

.content-wrapper p:last-child {
  margin-bottom: calc($header-padding / 2);
}

.blog-wrapper {
  @extend .content-wrapper;
  width: 100%;
  max-width: 100%;
  padding-left: 150px;
  padding-right: 150px;
}

@media screen and (max-width: 1100px) {
  .blog-wrapper {
    padding-left: 100px;
    padding-right: 100px;
  }
}

@media screen and (max-width: 900px) {
  .blog-wrapper {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media screen and (max-width: $sm-breakpoint) {
  .blog-wrapper {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.projects-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-flow: wrap;
}

.blog-list {
  flex-basis: 40%;
  display: flex;
  flex-direction: column;
  margin-top: 2em;
  border: 1px solid #ebe5d3;
  border-radius: 1em;
  margin-bottom: 2em;
}

.blog-wrapper h1 {
  text-align: center;
}

.blog-wrapper h2,
.blog-wrapper h3,
.blog-wrapper h4 {
  text-transform: uppercase;
  font-family: "Bebas Neue", "Helvetica Neue", "Helvetica", Arial, sans-serif;
}

.blog-wrapper ul li {
  list-style-type: disc;
  padding-top: 0.25em;
}

.blog-wrapper a {
  word-break: break-all;
  text-wrap: wrap;
  color: $green-color;
  text-decoration: underline;
}

.blog-wrapper code {
  word-break: break-all;
  text-wrap: wrap;
}

.blog-list-item {
  text-decoration: none;
  list-style-type: disclosure-closed;
  padding-right: 1em;
}

.blog-list-link {
  text-decoration: none;
  color: $forest-green;
  display: inline-block;
  &:hover {
    text-decoration: underline;
  }
}

.blog-date {
  text-align: right;
  margin: 0;
  font-size: 0.75em;
}

.leading,
.lead {
  text-align: center;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
  margin: 1em auto 2em auto;
  color: #777777;
}

.logo {
  padding: 0.5em;
  transform: rotate(0deg);
  transition: all 1.5s
    linear(
      0,
      0.087,
      0.218 2.5%,
      0.859 7.7%,
      1.11 10.1%,
      1.216,
      1.293,
      1.344,
      1.369 15.4%,
      1.373,
      1.368,
      1.355 17.9%,
      1.333 18.9%,
      1.264 20.8%,
      1.054 25.6%,
      0.966 28%,
      0.9 30.6%,
      0.88,
      0.87 33.2%,
      0.869,
      0.882,
      0.907 38.9%,
      0.98 43.6%,
      1.011 45.9%,
      1.035,
      1.046 51% 52.8%,
      1.042 54.8%,
      0.997 63.7%,
      0.988,
      0.984 68.6%,
      0.985 72.8%,
      1.001 81.5%,
      1.005 85.9% 90.7%,
      0.999
    );
}

.logo:hover {
  transform: rotate(1turn);
}

@keyframes spin {
  0% {
    rotate: 0deg;
  }
  55% {
    rotate: 1.25turn;
  }
  100% {
    rotate: 0deg;
  }
}

ul {
  list-style: none;
}

.nav-list,
.footer-list {
  display: flex;
  text-transform: uppercase;
  font-family: "Bebas Neue", "Helvetica Neue", "Helvetica", Arial, sans-serif;
  font-size: clamp(0.66rem, 2vw, 1rem);
}

.nav-list > li,
.footer-list > li {
  padding: 1em;
}

.nav-list > li > a,
.footer-list > li > a {
  text-decoration: none;
  letter-spacing: 0.5em;
  color: #997f34;
  font-weight: 900;
  text-shadow: 0 1px #0000006b;
}

/* Link underline squiggle from 
  Codrops(https://tympanus.net/Development/LineHoverStyles/)
*/

.item {
  counter-increment: itemcounter;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.link {
  cursor: pointer;
  font-size: 18px;
  position: relative;
  white-space: nowrap;
  color: var(--color-text);
}

.link::before,
.link::after {
  position: absolute;
  width: 100%;
  height: 1px;
  background: currentColor;
  top: 100%;
  left: 0;
  pointer-events: none;
}
.link::before {
  content: "";
  /* show by default */
}

.link--carme::before {
  display: none;
}

.link__graphic {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  fill: none;
  stroke: #997f34;
  stroke-width: 1px;
}

.link__graphic--stroke path {
  stroke-dasharray: 1;
  stroke-dashoffset: 1;
}

.link:hover .link__graphic--stroke path,
.active > .link__graphic--stroke path {
  stroke-dashoffset: 0;
}

.link__graphic--scribble {
  top: 100%;
}

.link__graphic--scribble path {
  transition: stroke-dashoffset 0.6s cubic-bezier(0.7, 0, 0.3, 1);
}

.link:hover .link__graphic--scribble path {
  transition-timing-function: cubic-bezier(0.8, 1, 0.7, 1);
  transition-duration: 0.3s;
}

.title-wrapper {
  font-style: normal;
  position: absolute;
  text-align: center;
  width: 100%;
  top: calc(50% - -1em);
  margin-top: -$header-padding;
}

h1 {
  font-family: "PaskRegular", "Palantino Linotype", "Palantino",
    "Times New Roman", "Times", serif;
  font-size: 44px;
  color: #997f34;
  margin: 0 auto;
  padding: 0px 32px;
}

@media screen and (max-width: 800px) {
  h1 {
    font-size: 32px;
  }
}
@media screen and (max-width: $sm-breakpoint) {
  h1 {
    font-size: 32px;
    padding: 0;
  }
}
@media screen and (max-width: 500px) {
  h1 {
    font-size: 24px;
  }
  .index h1 {
    width: 100%;
    font-size: 32px;
  }
}

.profile-picture {
  display: block;
  border-radius: 50%;
  margin: 1em auto;
  box-shadow: 0 0 0 2px $background-color, 0 0 0 3px $gold-color;
}

.social-link-list {
  margin: 1em auto 0 auto;
  text-align: center;
}

.social-link {
  display: inline-block;
  padding: 0 1em;
  transition: all 1s
    linear(
      0 0.1%,
      0.22 2.1%,
      0.86 6.5%,
      1.11 8.6%,
      1.3 10.7%,
      1.35 11.8%,
      1.37 12.9%,
      1.37 13.7%,
      1.36 14.5%,
      1.32 16.2%,
      1.03 21.8%,
      0.94 24%,
      0.89 25.9%,
      0.88 26.85%,
      0.87 27.8%,
      0.87 29.25%,
      0.88 30.7%,
      0.91 32.4%,
      0.98 36.4%,
      1.01 38.3%,
      1.04 40.5%,
      1.05 42.7%,
      1.05 44.1%,
      1.04 45.7%,
      1 53.3%,
      0.99 55.4%,
      0.98 57.5%,
      0.99 60.7%,
      1 68.1%,
      1.01 72.2%,
      1 86.7%,
      1 100%
    );
}

.social-link:hover {
  transform: scale(1.5);
}

footer {
  padding: $header-padding;
  padding-top: 0;
  position: absolute;
  width: 100%;
  bottom: 0;
  z-index: 1000;
}

.footer-list {
  display: flex;
  padding: 0;
  justify-content: space-between;
}

.work-list {
  display: flex;
  flex-basis: 60%;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.work-list:before {
  content: "";
}

@media screen and (max-width: $sm-breakpoint) {
  .work-list,
  .blog-list {
    flex-basis: 100%;
  }
  .work-list:before {
    content: "Projects";
    font-family: "PaskCondensed", "Palantino Linotype", "Palantino",
      "Times New Roman", "Times", serif;
    font-size: 2em;
    color: $green-color;
    text-shadow: 0 1px rgba(0, 0, 0, 0.1411764706);
    padding: 0;
    font-weight: 600;
  }
}

.work-list > a {
  flex-basis: 100%;
  flex-wrap: wrap;
}

.work-item {
  margin: 2em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 1em;
  border: 1px solid #ebe5d3;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: linear(
    0 0.1%,
    0.22 2.1%,
    0.86 6.5%,
    1.11 8.6%,
    1.3 10.7%,
    1.35 11.8%,
    1.37 12.9%,
    1.37 13.7%,
    1.36 14.5%,
    1.32 16.2%,
    1.03 21.8%,
    0.94 24%,
    0.89 25.9%,
    0.88 26.85%,
    0.87 27.8%,
    0.87 29.25%,
    0.88 30.7%,
    0.91 32.4%,
    0.98 36.4%,
    1.01 38.3%,
    1.04 40.5%,
    1.05 42.7%,
    1.05 44.1%,
    1.04 45.7%,
    1 53.3%,
    0.99 55.4%,
    0.98 57.5%,
    0.99 60.7%,
    1 68.1%,
    1.01 72.2%,
    1 86.7%,
    1 100%
  );
}

.work-thumbnail {
  height: 100px;
  width: 100px;
  margin: 0.5em;
}

.work-item:hover {
  transform: scale(1.05);
}

a .work-title,
a .work-tags {
  text-decoration: none;
}

.blog-title {
  text-align: center;
}

.work-title,
.blog-title {
  font-family: "PaskCondensed", "Palantino Linotype", "Palantino",
    "Times New Roman", "Times", serif;
  font-size: 2em;
  color: $green-color;
  text-shadow: 0 1px #00000024;
  padding: 0;
}

.work-details {
  flex: 1;
  flex-direction: column;
}

.work-description {
  margin-bottom: 1em;
  margin-right: 1em;
  text-decoration: none;
}

.tag-list {
  line-height: normal;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-end;
  align-items: flex-start;
  align-self: flex-end;
}

.work-tags {
  text-transform: uppercase;
  font-family: "Bebas Neue", "Helvetica Neue", "Helvetica", Arial, sans-serif;
  padding: 0.25em 0.5em;
  background-color: transparent;
  border: 1px solid $gold-color;
  border-radius: 1em;
  color: $gold-color;
  margin-left: 0.25em;
  margin-bottom: 1em;
}

.work-tags:last-of-type {
  margin-right: 0.5em;
}

.blog-tags {
  @extend .work-tags;
  font-size: 0.75em;
  display: inline-block;
  word-break: keep-all;
  text-wrap: nowrap;
  border: none;
}

.work-wrapper {
  display: flex;
  flex-direction: row;
}

.hero-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.hero-image {
  width: -webkit-fill-available;
  height: fit-content;
  padding: 1em;
  box-shadow: 0 0 0 1em $background-color, 0 0 0 1.1em $gold-color;
  margin: 2em;
  border-radius: 2em;
}

.subtitle-wrapper {
  font-size: 0.75em;
  display: flex;
  flex-direction: column;
}

.subtitle-wrapper h2 {
  font-family: "Bebas Neue", "Helvetica Neue", "Helvetica", Arial, sans-serif;
  margin-top: 1.25em;
}

.content {
  padding: 0 2em;
  margin-bottom: 2em;
}

@media screen and (max-width: $sm-breakpoint) {
  .content {
    padding: 0;
  }
}

.content h3 {
  font-family: "PaskExpanded", "Palantino Linotype", "Palantino",
    "Times New Roman", "Times", serif;
}

.content h4 {
  font-family: "Bebas Neue", "Helvetica Neue", "Helvetica", Arial, sans-serif;
  margin-top: 1.25em;
}

.image-row-wrapper {
  padding: 1em 0;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

/* Media Queries */

@media (max-width: 1200px) {
  $header-padding: 16px;
  .container {
    border: calc($header-padding / 2) solid #284c4a;
    box-shadow: 0 0 0 1px #997f34 inset, 0 0px 0 $header-padding #284d4a inset,
      0 0 0 calc($header-padding + 1px) #997f34 inset;
  }
  .container-exit {
    border-width: calc($header-padding / 4);
  }
  .container.not-index {
    border-width: calc($header-padding / 4);
  }
  header,
  footer {
    padding: $header-padding;
  }
  .hero-image {
    height: fit-content;
  }
}

@media (max-width: $sm-breakpoint) {
  .work-list {
    margin-top: 2em;
  }
  .work-wrapper {
    flex-direction: column;
  }
  .work-item {
    margin: 1em;
    padding: 1em;
    flex-direction: column;
  }
  .work-tags {
    font-size: 0.75em;
  }
  .work-title {
    font-size: 1em;
    padding: 0 0.25em;
    text-align: center;
  }
  .work-thumbnail {
    margin: 0;
  }
  .hero-image {
    margin: 2em 1em 1em 1em;
    padding: 0.5em;
    box-shadow: 0 0 0 0.5em $background-color, 0 0 0 0.6em $gold-color;
  }
  .subtitle-wrapper {
    font-size: 0.75em;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    flex-wrap: wrap;
  }
  .subtitle-wrapper h2 {
    margin: 0;
    flex-basis: 100%;
  }
  .subtitle-wrapper span {
    padding: 0.5em;
    border: 1px solid $gold-color;
    color: $gold-color;
    font-family: "Bebas Neue", "Helvetica Neue", "Helvetica", Arial, sans-serif;
    text-transform: uppercase;
    border-radius: 1em;
    margin-right: 0.5em;
    margin-bottom: 0.5em;
  }
}
